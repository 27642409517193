import { useState, useMemo } from "react";
import { ApolloClient, gql, useApolloClient } from "@apollo/client";
import { AnimalReading } from "./types";
import { sub } from "date-fns";

export const loadReadingHistoryQuery = gql`
  query getAnimalReadingHistory(
    $animalId: String!
    $type: String!
    $from: AWSDateTime
  ) {
    getAnimalReadingHistory(animalId: $animalId, type: $type, from: $from) {
      items {
        type
        booleanValue
        datetime
        floatValue
        intValue
        stringValue
      }
      nextToken
    }
  }
`;

const loadReadings = async (
  apollo: ApolloClient<any>,
  animalId: string,
  type: string
) => {
  let nextToken: string | undefined;
  let ret: AnimalReading[] = [];
  // do {
  const result = await apollo.query({
    query: loadReadingHistoryQuery,
    variables: {
      animalId,
      type,
      from: sub(Date.now(), { months: 12 }).toISOString(),
      nextToken,
    },
  });
  ret = [...ret, ...(result.data?.getAnimalReadingHistory?.items ?? [])];
  nextToken = result.data?.getAnimalReadingHistory?.nextToken;
  // } while (nextToken != null);
  // console.debug("Query result", type, ret);
  return ret;
};

export const useAnimalReadingsType = (animalId: string, type: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [readings, setReadings] = useState<AnimalReading[]>([]);
  const apollo = useApolloClient();

  useMemo(async () => {
    setReadings(await loadReadings(apollo, animalId, type));
    setLoading(false);
  }, [apollo, animalId, type]);

  const reload = useMemo(
    () => async () => {
      setLoading(true);
      setReadings(await loadReadings(apollo, animalId, type));
      setLoading(false);
    },
    [apollo, animalId, type]
  );

  return {
    loading,
    readings,
    reload,
  };
};

const loadAllReadings = async (
  apollo: ApolloClient<any>,
  animalId: string,
  type: string
) => {
  let nextToken: string | undefined;
  let ret: AnimalReading[] = [];
  let queries = 10;
  do {
    const result = await apollo.query({
      query: loadReadingHistoryQuery,
      variables: {
        animalId,
        type,
        from: sub(Date.now(), { months: 12 }).toISOString(),
        nextToken,
      },
    });
    ret = [...ret, ...(result.data?.getAnimalReadingHistory?.items ?? [])];
    nextToken = result.data?.getAnimalReadingHistory?.nextToken;
    console.debug("NextToken", animalId, type, nextToken, !!nextToken, queries);
  } while (!!nextToken && --queries > 0);
  return ret;
};

export interface ExtendedAnimalReading extends AnimalReading {
  animalId: string;
}

export const useMultipleAnimalReadingsTypes = (
  animalId: string,
  types: string[]
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [readings, setReadings] = useState<ExtendedAnimalReading[]>([]);
  const apollo = useApolloClient();

  const reload = useMemo(
    () => async () => {
      setLoading(true);
      setReadings([]);
      setProgress(0);
      for (const type of types) {
        setProgress((p) => p + 1);
        const batch = await loadAllReadings(apollo, animalId, type);
        setReadings((reads) => [
          ...reads,
          ...batch.map((r) => ({ animalId, ...r })),
        ]);
      }
      setLoading(false);
    },
    [apollo, animalId, types]
  );

  useMemo(async () => {
    await new Promise((s) => setTimeout(s, 500));
    await reload();
  }, [reload]);

  return {
    loading,
    readings,
    progress,
    progressTotal: types.length,
    reload,
  };
};
