import { useMemo, useState } from "react";
import { useQuery } from "../../graphql/Query";
import { inflate } from "pako";

export interface AnimalDiagnosticData {
  implantId: string;
  deviceId: string;
  startTime: string;
  endTime: string;
  metrics: Record<string, any>;
  logs: string[];
  blob: string;
}

const unpackDiagnostic = async (
  data: string
): Promise<AnimalDiagnosticData | undefined> => {
  const compressed = Buffer.from(data, "base64");
  const raw = inflate(compressed, { to: "string" });
  const ret = JSON.parse(raw);
  return ret;
};

export const useAnimalDiagnostics = (animalId?: string) => {
  const [diagnostics, setDiagnostics] = useState<AnimalDiagnosticData[]>();
  const [parsing, setParsing] = useState(true);
  const { loading, data, ...props } = useQuery(
    `query GetAnimalDiagnostics($animalId: String!) {
        adminGetAnimalDiagnostics(animalId: $animalId) {
          nextToken
          items {
            animalId
            data
            startTime
            endTime
          }
        }
      }
      
    `,
    { animalId }
  );

  useMemo(async () => {
    setParsing(true);
    const ret: AnimalDiagnosticData[] = [];
    for (const item of data?.adminGetAnimalDiagnostics?.items ?? []) {
      const data = await unpackDiagnostic(item.data);
      if (data) {
        ret.push(data);
      } else {
        console.warn("Bad Diagnostic", item);
      }
    }
    setDiagnostics(ret);
    setParsing(false);
  }, [data]);

  return { ...props, loading: loading || parsing, diagnostics };
};
